import $ from "jquery";
import "slick-slider";

/**
 * Detects the number of slider items per viewport
 * Slider need >> data-slider-items="3|2|1" << in Slider-Container
 *
 * @function
 * @param {obj}   element Object of Slider
 */

const getSliderItems = (element) => {
    const data = ($(element).data('slider-items') || '1|1|1' ).split('|');
    const items = {
        Large: parseInt(data[0]),
        Medium: parseInt(data[1]),
        Small: parseInt(data[2])
    };

    return items;
};

/**
 * Responsive setting for different viewport
 * Slider need >> data-slider-items="3|2|1" << in Slider-Container
 *
 * @function
 * @param {string}   items   Number of slides items per viewport
 */

const getSliderResponsiveSetting = (items) => {
    return [
        {
            breakpoint: 1040, // TODO: ADD a Script for media queries breakpoints for JS
            settings: {
                slidesToShow: items.Large,
                slidesToScroll: items.Large,
            }
        },
        {
            breakpoint: 768,  // TODO: ADD a Script for media queries breakpoints for JS
            settings: {
                slidesToShow: items.Medium,
                slidesToScroll: items.Medium
            }
        },
        {
            breakpoint: 480,  // TODO: ADD a Script for media queries breakpoints for JS
            settings: {
                slidesToShow: items.Small,
                slidesToScroll: items.Small
            }
        }
    ];
};

/**
 * Slider-Arrows Button HTML Markup Template
 *
 * @function
 * @param {string}   cssClass   for Prev and Next arrows
 * @param {string}   title      for Button-Title
 * @param {string}   svg        for SVG arrow-icons
 */

const getButtonTPL = (cssClass, title, svg) => {
    let buttonTPL = `<button type='button' class='${cssClass}' title='${title}'>`;
        buttonTPL += `<svg class='icon icon--svg' width='16' height='16' focusable='false' aria-hidden='true'>`;
        buttonTPL += `<use xlink:href='${svg}'></use></svg>`;
        buttonTPL +=  `</button>`;
    return buttonTPL;
};


$('.js-slider').each((index, element) => {

    const items = getSliderItems(element);
    const arrowContainer = 'slick-arrows';
    const $sliderContainer = $(element).next(".slider__container");
    const arrowLabelPrev = $(element).data('arrow-label-prev') || '';
    const arrowLabelNext = $(element).data('arrow-label-next') || '';

    if ($(element).data('arrow-position') === 'bottom') {
        $("<div class='" + arrowContainer + "'></div>").appendTo($sliderContainer);
    }

   let defaults = {
        accessibility: true,
        arrows: true,
        slidesToShow: items.Large,
        slidesToScroll: items.Large,
        responsive: getSliderResponsiveSetting(items),
        prevArrow: getButtonTPL('slick-prev', arrowLabelPrev, '#svg__icon-arrow-left'),
        nextArrow: getButtonTPL('slick-next', arrowLabelNext, '#svg__icon-arrow-right'),
   };


/**
   * If the slider arrows are to be positioned below the slider,
   * the slider HTML markup requires this attribute: data-arrow-position="bottom"
   */

    const arrowsBottom = {
        appendArrows: $sliderContainer.find("." + arrowContainer)
    };

    if ($(element).data('arrow-position') === 'bottom') {
        $.extend(defaults, arrowsBottom);
    }

    $(element).slick(defaults);
});




