import $ from "jquery";

$(document).ready(() => {
  let jsonUrl = $("[data-basket]").data("basket");

  const getJson = function () {
    jsonUrl = "FDB/WS/merkzettel/navnode"; //TODO

    $.ajax({
      url: jsonUrl,
      success: function (result) {
        var toSplit = result.trim();

        if (toSplit.replace(/\s/g, "") == "") {
          $("[data-basket]").find(".link--label").html("0");
          $("[data-basket-action]").removeClass("saved");
          $("[data-basket-action]").attr("data-basket-action", "add");
        } else {
          var arraySplited = toSplit.split(",");
          var itemsNumber = arraySplited.length;

          $("[data-basket]").find(".link--label").html(itemsNumber);
          $("[data-basket-action]").removeClass("saved");
          $("[data-basket-action]").attr("data-basket-action", "add");

          $.each(arraySplited, function (index, item) {
            //console.log(index + ": " + item);
            $("[data-basket-id=" + item + "]").addClass("saved");
            $("[data-basket-id=" + item + "]").attr("data-basket-action", "remove");
          });
        }
      },
      error: function (error) {
        console.log("ERROR: load ajax not loaded: " + error);
      }
    });
  };

  const addRemove = function () {
    let $btnAddBasket = $("[data-basket-action]");

    $btnAddBasket.bind("click", function () {
      let baseUrl = $("base").attr("href");
      let urlBasket = $(this).data("href");
      let paramBasket = $(this).data("basket-id");
      let actionBasket = $(this).attr("data-basket-action");
      let actionBasketParam;

      if (actionBasket == "add") {
        actionBasketParam = "=%2B1";
      } else if (actionBasket == "remove") {
        actionBasketParam = "=-1";
      } else {
        console.log("error actionBasketParam = 0");
        actionBasketParam = "=0";
      }

      let urlToCall = baseUrl + urlBasket + "?cart" + paramBasket + actionBasketParam;

      let elem = $(this);

      $.ajax({
        url: urlToCall,
        success: function () {
          elem.addClass("do-timer");
          getJson();
          setTimeout(function () {
            $btnAddBasket.removeClass("do-timer");
          }, 2000);
        },
        error: function (error) {
          console.log("ERROR: " + error);
        }
      });
    });
  };
  addRemove();
  getJson();
});
