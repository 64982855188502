import $ from "jquery";

$(document).ready(() => {

  var closeButton = $('.link--close'),
    navMetroLink = $('.open-flyout'),
    navbarLayer = $('.mainnav__flyout');

  var closeNavMetroSecond = function() { // for all

    event.preventDefault();
    navbarLayer.removeClass('active');
    navMetroFirstLevel.removeClass('addBorder');
    navbarLayer.css('animation', 'openLayerMD 1s');
    return false;
  };

  var openCloseNavMetroSecond = function() { // for all
    event.preventDefault();

    var dataOpener = $(this).data('opener'),
      toOpen = $('.mainnav__flyout--' + dataOpener),
      mq = window.matchMedia("(max-width: 480px)");

    if (toOpen.hasClass('active')) {
      $(this).removeClass('active');
      navbarLayer.removeClass('active');
      //$('body').removeAttr('class');
    } else {

      $(this).addClass('active');

      if ($('.mainnav__flyout.active').length) {
        toOpen.css('animation', 'openLayerMD 0');
      }

      navbarLayer.removeClass('active');
      toOpen.addClass('active');

      if (mq.matches) {
        event.preventDefault();

        var heightValue = 70
        $('html, body').animate({
          scrollTop: toOpen.offset().top - heightValue
        }, 1000);
      }
    }
  };

  navMetroLink.on('click', openCloseNavMetroSecond);
  closeButton.on('click', closeNavMetroSecond);
});