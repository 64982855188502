import $ from "jquery";

$(document).ready(() => {

  var $thisOpener = $('[class*="--accordion-opener"]'),
    $allSiblingsTabs = $('[data-group]'),
    breakpoints = ['lg', 'xl', 'xxl'];

  var toggleJs = function() {
    $thisOpener.each(function() {

      var $thisElement = $(this),
        thisExpand = $thisElement.data('expanded'),
        $toCloseNext = $thisElement.next();

      var thisBreakpoint = window.getComputedStyle(document.querySelector('body'), ':after').getPropertyValue('content').replace(/"/g, '');

      if (thisExpand) {
        $thisElement.addClass('expanded');
      } else if (breakpoints.includes(thisBreakpoint) && $toCloseNext.hasClass('ul--first-level')) {
        $('.ul--first-level').removeAttr('style');
      } else {
        $thisElement.removeClass('expanded');
        $thisElement.find('use').attr('xlink:href', '#svg__icon-plus');

        $toCloseNext.hide();
      }
    });
  };

  var openCloseAccordion = function() {
    event.preventDefault();

    var $thisElement = $(this),
      $toOpenNext = $thisElement.next();

    if ($thisElement.hasClass('expanded')) {
      $thisElement.removeClass('expanded');
      $toOpenNext.slideUp();
      $thisElement.find('use').attr('xlink:href', '#svg__icon-plus');
    } else {

      if ($thisElement.data('group')) {
        var dataGroup = $thisElement.data('group');
        $allSiblingsTabs.removeClass('expanded');
        $allSiblingsTabs.next().hide();
      }

      $thisElement.addClass('expanded');
      $toOpenNext.slideDown();
      $thisElement.find('use').attr('xlink:href', '#svg__icon-minus');

      if ($thisElement.data('scroll') === true) {
        $('html, body').delay(1000).animate({
          scrollTop: $thisElement.offset().top - 50
        }, 1000);
      }
    }
  };

  var animateResults = function() {
    $('#hits--count').each(function() {
      $(this).prop('Counter', 0).animate({
        Counter: $(this).text()
      }, {
        duration: 1000,
        easing: 'swing',
        step: function(now) {
          let parts = now.toString().split(".");

          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          $(this).text(parts[0]);
        }
      });
    });
  };

  var scrollSearch = function(toElem) {

    var $inputSearch = $('input.input--search');
    var inputOk = false;

    if ($.trim($inputSearch.val()) !== '') {
      inputOk = true;
      console.log('is set 8');
    }

    if (($('.btn--filter-footer').length) || inputOk) {
      console.log('I am here');
      var $scrollTo = $('#hits--count');
      var scrollPosition = $scrollTo.offset().top;

      $('body,html').stop().animate({
        scrollTop: scrollPosition - 20
      }, 400);
    } else {
      return false;
    }
  };

  scrollSearch();
  toggleJs();
  animateResults();
  $(window).on('resize', toggleJs);
  $thisOpener.on('click', openCloseAccordion);
});