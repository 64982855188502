import $ from "jquery";

const $toTop = $(".to-top");
const $toTopButton = $toTop.find(".uplink");

$(window).scroll(function() {
  if ($(window).scrollTop() > 300) {
    $toTop.fadeIn();
  } else {
    $toTop.fadeOut();
  }
});

$toTopButton.on("click", event => {
  event.preventDefault();
  $("html, body").animate(
    {
      scrollTop: 0
    },
    300
  );
});
