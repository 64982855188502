import $ from "jquery";
import "@bmwi/mainnav/src/_index";
import "@bmwi/sidebar/src/_index";
import "@bmwi/service/src/_index";
import "@bmwi/slider/src/_index";
import "@bmwi/totop/src/_index";
import "@bmwi/tabaccordion/src/_index";
import "@bmwi/autosuggest/src/_index";
import "@bmwi/basket/src/_index";

window.jQuery = $;