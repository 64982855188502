import $ from "jquery";

$(document).ready(() => {


  var servicelayeropener = $('.link--service-opener'),
    servicelayercloser = $('.link--service-close'),
    layertoopen = $('.service--layer');

  var layerOpener = function() {
    event.preventDefault();
    layertoopen.addClass('open').css('transition','right 1s, opacity .4s');
  };

  var layerCloser = function() {
    event.preventDefault();
    layertoopen.removeClass('open');
  };

  servicelayeropener.on('click', layerOpener);
  servicelayercloser.on('click', layerCloser);

});